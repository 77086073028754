export const answerNewDatas = {
  'no-answer': {
    buttonText: 'Mark as completed'
  },
  text: {
    value: '',
    lenience: null,
    isCaseInsensitive: true,
    isSpecialCharacterInsensitive: true
  },
  'text-ai': {
    value: ''
  },
  qrbarcode: {
    value: '',
    lenience: null
  },
  number: {
    value: '',
    positive_lenience: null,
    negative_lenience: null
  },
  integer: {
    value: '',
    positive_lenience: null,
    negative_lenience: null
  },
  location: {
    lat: null,
    lon: null,
    radius_in_meters: null
  },
  direction: {
    lat: null,
    lon: null,
    radius_in_meters: null
  },
  'multiple-choice': {
    value: '',
    is_valid: false,
    is_solo_answer: false
  },
  'free-multiple-choice': {
    value: ''
  },
  'object-recognition': {
    value: '',
    lenience: null
  },
  'image-similarity': {
    value: ''
  },
  'judged-image-ai': {
    value: ''
  },
  'audio-words-recognition': {
    value: '',
    language: ''
  },
  'fill-in-the-blanks': {
    value: 'This is a {red} apple, and I love {straw}berries!',
    isCaseInsensitive: true,
    isSpecialCharacterInsensitive: true
  },
  'word-search': {
    value: '',
    isHidden: false
  },
  'matching-pairs': {
    value: '',
    value1: ''
  },
  'scrambled-phrase': {
    value: ''
  },
  'image-jigsaw': {
    value: '',
    noOfXPieces: 4,
    noOfYPieces: 4,
    showCorrectPieces: true
  },
  'combination-lock': {
    value: 'FIRE',
    config: '(M,S,F,L,Q,C)(P,Q,N,S,I)(F,R,L,A,N,S)(E,P,Q,I,S,J)'
  }
}
