<template>
  <div>
    <button v-for="category in taskCategories"
    :key="category"
    class="grey-rounded-small-button"
    @click="emitCategoryChange(category)">
    {{ category }}
    <i class="fas fa-edit ml-2" style="font-size: 0.6rem; color: grey;" @click.stop="confirmAndRenameCategory(category)"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TaskCategoriesManager',
  props: ['stages', 'stageIndex', 'challengeIndex'],
  data () {
    return {
      showContinueGame: false
    }
  },
  computed: {
    taskCategories () {
      const taskCategories = new Set()
      this.stages.forEach(stage => {
        stage.challenges.forEach(challenge => {
          if (challenge.taskCategory) taskCategories.add(challenge.taskCategory)
        })
      })
      return Array.from(taskCategories).sort()
    }
  },
  methods: {
    emitCategoryChange (category) {
      this.$emit(
        'update-task-category',
        { category, stageIndex: this.stageIndex, challengeIndex: this.challengeIndex }
      )
    },
    confirmAndRenameCategory (category) {
      const confirmed = confirm(`Do you want to edit the task category for all tasks with the category "${category}"?`)
      if (confirmed) {
        const newCategoryName = prompt('Enter the new category name:', category)
        if (newCategoryName && newCategoryName !== category) {
          this.stages.forEach((stage, stageIndex) => {
            stage.challenges.forEach((challenge, challengeIndex) => {
              if (challenge.taskCategory === category) {
                this.$emit(
                  'update-task-category',
                  { category: newCategoryName, stageIndex, challengeIndex }
                )
              }
            })
          })
        }
      }
    }
  },
  mounted () {
    console.log('stages', this.stages)
  }
}
</script>

<style>
</style>
